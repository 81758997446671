import { environment } from './../../../environments/environment';
import { computed, Injectable, signal } from '@angular/core';
import { AuthService as AddictionAuthService } from 'addiction-auth';
import { Observable, filter } from 'rxjs';
import { AuthenticationToken } from 'addiction-auth';
import { DataleanBaseApiService } from 'addiction-components';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApplicationUser } from '../models/application-user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  async logout(): Promise<void> {
    await this.authService.logout(true);
  }
  private _userData = signal<ApplicationUser | undefined>(undefined);
  userData = computed(() => this._userData())

  constructor(private authService: AddictionAuthService, private baseApi: DataleanBaseApiService) {
    this.authService.canActivateProtectedRoutes$.pipe(takeUntilDestroyed(),filter((is) => !!is)).subscribe(() => {
      const token = new AuthenticationToken(this.authService.idToken, undefined);
      const userUUID = token.getPayloadParameter('userUUID');
      if (userUUID) {
        this.fetchUser(userUUID)
          .subscribe({
            next: (user) => this._userData.set(user)
          })
      }
    });
  }

  fetchUser(userUUID: string): Observable<ApplicationUser> {
    return this.baseApi.getEntity(environment.userUrl, userUUID, []);
  }

  updateUser(user: Partial<ApplicationUser>) {
    const userUUID = this.userData()?.uuid;
    if(userUUID) {
      return this.baseApi.patchOne<ApplicationUser>(environment.userUrl, userUUID, user)
    } else {
      throw new Error('User not valorized')
    }
  }
}
