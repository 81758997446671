export class AuthenticationToken {
  constructor(private token: string, private expirationTimestamp?: string) {
    if (!this.token) {
      throw new Error('Token cannot be empty');
    }

    const expPayload = this.getPayloadParameter('exp')
    if (!this.expirationTimestamp && expPayload) {
      this.expirationTimestamp = `${+expPayload * 1000}`;
    }
  }

  get Token() {
    return this.token;
  }

  get ExpirationTimeStamp() {
    return this.expirationTimestamp;
  }

  getPayloadParameter(parameterKey: string): string | undefined {
    const payload = this.getTokenPayload();
    if(payload) {
      return this.decodePayload(payload)[parameterKey];
    }
    return undefined;
  }

  private getTokenPayload() {
    if (this.token) {
      const tokenComponents = this.token.split('.');
      if (tokenComponents.length === 3) {
        return tokenComponents[1];
      }
    }
    return undefined;
  }

  private decodePayload(payload: string): any {
    try {
      return JSON.parse(atob(payload));
    } catch (e) {
      return {};
    }
  }
}
